exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin/[...].tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-auth-github-callback-tsx": () => import("./../../../src/pages/auth/github/callback.tsx" /* webpackChunkName: "component---src-pages-auth-github-callback-tsx" */),
  "component---src-pages-auth-login-tsx": () => import("./../../../src/pages/auth/login.tsx" /* webpackChunkName: "component---src-pages-auth-login-tsx" */),
  "component---src-pages-auth-registrations-tsx": () => import("./../../../src/pages/auth/registrations/[...].tsx" /* webpackChunkName: "component---src-pages-auth-registrations-tsx" */),
  "component---src-pages-docs-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/docs/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-docs-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-snapshots-tsx": () => import("./../../../src/pages/snapshots/[...].tsx" /* webpackChunkName: "component---src-pages-snapshots-tsx" */),
  "component---src-pages-user-dashboard-index-tsx": () => import("./../../../src/pages/user/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-user-dashboard-index-tsx" */),
  "component---src-pages-user-profile-index-tsx": () => import("./../../../src/pages/user/profile/index.tsx" /* webpackChunkName: "component---src-pages-user-profile-index-tsx" */)
}

