import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)

export const d = dayjs

type Format = "full" | "short" | "relative" | "timezone";

export default class ELDate {
  _utc: number;
  _date: dayjs.Dayjs;
  timeOffset: number = 0;
  constructor(utc: number) {
    this._utc = utc;
    this._date = dayjs.utc(utc);

    // date object return right timezone offset when initialized with no params.
    this.timeOffset = new Date().getTimezoneOffset();
  }

  get relative() {
    return this.format('relative');
  }

  get value() {
    return this._date.utcOffset(-this.timeOffset);
  }

  format(kind: Format = 'full') {
    switch (kind) {
      case 'timezone':
        return this.value.format('YYYY/MM/DD HH:mm:ss ZZ');
      case 'full':
        return this.value.format('YYYY/MM/DD HH:mm:ss');
      case 'short':
        return this.value.format('YYYY/MM/DD');
      case 'relative':
        return this.value.from(dayjs());
    }
  }
}

