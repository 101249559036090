import { instance } from "./index";
import { factory } from "./models/user";
import { AxiosError } from "axios";

export const fetchGithubRequestInfo = async () => {
  const _path = `/auth/oauth/github/request_uri`;
  const res = await instance.get(_path);
  return res.data;
};

export const refreshToken = async () => {
  const _path = `/auth/token`;
  const res = await instance.patch(_path);
  return res.data;
};

export const logout = async () => {
  const _path = `/auth/token`;
  const res = await instance.delete(_path);
  return res.data;
};

export const signUpOrSignIn = async ({ code }: { code: string }) => {
  const _path = `/auth/oauth/github`;
  const res = await instance.post(_path, { code });
  res.data.data = factory(res.data.data);
  return res.data;
};

export const unauthorizedError = (e: AxiosError<any>) => {
  return e.response?.status === 401;
};

export const tokenExpiredError = (e: AxiosError<any>) => {
  if (!e.response) {
    return false;
  }

  const data = e.response.data;
  if (!data) {
    return false;
  }
  return (
    e.response.status === 401 &&
    data?.errors?.[0]?.code === "auth:token_expired"
  );
};

