import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";
import { cls } from "@lib/style";
import Icon, { IconType as IconName } from "@components/icon";

const styles = {
  container: css`
    z-index: var(--z-index-toast);
    position: fixed;
    top: -32px;
    width: 100%;
  `,
  content: css`
    margin: auto;
    transition: top 0.2s;
    max-width: 600px;
  `,
  toast: css`
    min-width: 200px;
    padding: 16px;
    margin-bottom: 8px;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 var(--shadow-color);

    opacity: 0;
    transition: opacity 0.05s;

    display: flex;
    align-items: center;
  `,
  show: css`
    top: 32px;
  `,
  showToast: css`
    opacity: 1;
  `,
  icon: css`
    margin-right: 16px;
  `,
  info: css`
    background: var(--info-color);
    color: var(--font-color-light);
  `,
  warning: css`
    background: var(--warning-color);
    color: var(--font-color-light);
  `,
  error: css`
    background: var(--danger-color);
    color: var(--font-color-light);
  `,
  success: css`
    background: var(--success-color);
    color: var(--font-color-light);
  `,
  message: css`
    padding: 0;
    margin: 0;
  `,
};

interface Config {
  kind: "info" | "warning" | "error" | "success";
  message: string;
  duration?: number;
  onDismiss?: () => void;
}

const icons = {
  info: "info",
  warning: "info",
  error: "info",
  success: "check",
};

export default function Toast() {
  const [defaultConfig, setDefaultConfig] = useState<Config>({
    kind: "info",
    message: "",
    duration: 3000,
  });
  const [queue, setQueue] = useState<Config[]>([]);

  const _show = (config: Config) => {
    const _queue = [...queue, config];
    setQueue(_queue);

    return (): Promise<undefined> => {
      return new Promise((resolve) => {
        setTimeout(() => {
          const _queue = queue.slice(1);
          setQueue(_queue);
          resolve(undefined);
        }, config?.duration || 3000);
      });
    };
  };

  useEffect(() => {
    Toast.show = _show;
    Toast.info = (message: string) =>
      _show({ ...defaultConfig, message, kind: "info" });
    Toast.error = (message: string) =>
      _show({ ...defaultConfig, message, kind: "error" });
    Toast.success = (message: string) =>
      _show({ ...defaultConfig, message, kind: "success" });
    Toast.setDefaultConfig = (_config: Config) => setDefaultConfig(_config);
  }, [queue]);

  return (
    <div
      className={cls({
        [styles.container]: true,
        [styles.show]: queue.length > 0,
      })}
    >
      <div
        className={cls({
          [styles.content]: true,
          [styles.show]: queue.length > 0,
        })}
      >
        {queue.map((config: Config, index: number) => {
          const iconName = icons[config?.kind || "info"] as IconName;

          return (
            <div
              key={`toast-${index}`}
              className={cls({
                [styles.toast]: true,
                [styles.showToast]: queue.length > 0,
                [styles.info]: config.kind === "info",
                [styles.warning]: config.kind === "warning",
                [styles.error]: config.kind === "error",
                [styles.success]: config.kind === "success",
              })}
            >
              <div className={styles.icon}>
                <Icon name={iconName} />
              </div>
              <p className={styles.message}>{config?.message}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const initialFunc = (_: string) => {
  return () => Promise.resolve(undefined);
};

Toast.show = (_config: Config) => {};
Toast.success = initialFunc;
Toast.info = initialFunc;
Toast.error = initialFunc;
Toast.setDefaultConfig = (_config: Config) => {};
