import { handler, setupSetter, Base, DateFields } from "@lib/models";
import { camelCaseKeys } from "@lib/jsonapi/util";

type GithubInfo = Record<string, any>;

type GithubData = {
  avatarUrl: string;
  url: string;
  login: string;
  createdAt: number;
  updatedAt: number;
}

export interface OauthGithubParams {
  id: string;
  userId: string;
  indetifer: string;
  username: string;
  data: GithubData;
  scope: string;

  createdAt: number;
  updatedAt: number;
}

export class OauthGithubModel extends Base<OauthGithubParams> {
  data: GithubInfo = {};

  constructor(params: OauthGithubParams) {
    super(params);

    if (params.data) {
      this.data = camelCaseKeys(params.data);
    }
  }
}

export type OauthGithub = OauthGithubModel &
  OauthGithubParams &
  DateFields;

export const factory = (params: OauthGithubParams): OauthGithub =>
  new Proxy<OauthGithub>(
    new OauthGithubModel(params) as OauthGithub,
    handler({}),
  );

export const set = setupSetter<OauthGithubParams, OauthGithub>(factory);
