import React, { useEffect, useMemo } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Provider as AuthProvider } from "@contexts/auth";
import { clean } from "@lib/url";
import Toast from "@lib/toast";
import { ReturnErrorHandler, setErrorHandler } from "@services/api";
import {
  refreshToken,
  tokenExpiredError,
  unauthorizedError,
} from "@services/api/auth";
import { translate } from "@locales/index";
import { navigateWith } from "@lib/navigate";
import { logout } from "@services/api/auth";

const errorHandler =
  ({ isPublic }: { isPublic: boolean }) =>
  async (e: AxiosError<any>): Promise<ReturnErrorHandler> => {
    if (e.code === "ERR_NETWORK") {
      Toast.error(
        ui.t("Network Error. Please check your connection and try again.")
          .value,
      )();
      return {
        e,
        resolved: true,
      };
    }

    if (!e.response) {
      return {
        e,
        resolved: false,
      };
    }

    if (tokenExpiredError(e)) {
      try {
        await refreshToken();
        return {
          e,
          resolved: false,
        };
      } catch (error) {
        console.error(error);
        await logout();
        navigateWith("/auth/login", () => {
          Toast.error(ui.t("Request Was Unathorized").value)();
        });

        return {
          e,
          resolved: true,
        };
      }
    }

    if (e.response.status === 404) {
      navigateWith("/404", () => {
        Toast.error(ui.t("Page Not Found").value)();
      });

      return {
        e,
        resolved: true,
      };
    }

    if (unauthorizedError(e) && !isPublic) {
      navigateWith("/auth/login", () => {
        Toast.error(ui.t("Request Was Unathorized").value)();
      });

      return {
        e,
        resolved: true,
      };
    }

    return {
      e,
      resolved: false,
    };
  };

interface Props {
  children: React.ReactNode;
  location: Location;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000,
      retry: 1,
    },
  },
});

const publicPaths = {
  "/": { exact: true },
  "/auth/login": { exact: true },
  "/auth/github/callback": { exact: true },
  "/admin/emails/.*": { exact: false },
  "/snapshots/.*": { exact: false },
  "/docs/.*": { exact: false },
};

type PublicPathKey = keyof typeof publicPaths;

const ui = translate("en").t("ui");

export default function Base({ children, location }: Props) {
  const [ready, setReady] = React.useState(false);
  const isPublic = useMemo(() => {
    if (!location) {
      return false;
    }

    return Object.keys(publicPaths).some((it) => {
      const key = it as PublicPathKey;
      if (!!publicPaths[key].exact) {
        return (
          clean(key) === location.pathname ||
          clean(key + "/") === location.pathname
        );
      }

      return (
        clean(key + "/") === location.pathname ||
        new RegExp(key).test(location.pathname)
      );
    });
  }, [location?.pathname]);

  useEffect(() => {
    setReady(true);
  }, []);

  // wait for render css
  if (!ready) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Content isPublic={isPublic}>
        <AuthProvider isPublic={isPublic}>{children}</AuthProvider>
      </Content>
    </QueryClientProvider>
  );
}

interface ContentProps {
  children: React.ReactNode;
  isPublic: boolean;
}

function Content({ isPublic, children }: ContentProps) {
  useEffect(() => {
    setErrorHandler(errorHandler({ isPublic }));
  }, []);

  return <div>{children}</div>;
}
