import React from "react";
import { css } from "@emotion/css";
import {
  IoAlertCircleOutline,
  IoCheckmark,
  IoCaretForward,
  IoCaretDown,
  IoCaretUp,
  IoFolderSharp,
  IoDocumentOutline,
  IoRefresh,
  IoMenuOutline,
  IoChatboxOutline,
  IoChevronDown,
  IoChevronUp,
  IoAdd,
  IoCreateOutline,
  IoCloseOutline,
  IoEnterOutline,
  IoEllipsisVerticalSharp,
  IoCopyOutline,
  IoTrashOutline,
  IoSearchOutline,
  IoLogoGithub,
  IoArrowUndoOutline,
} from "react-icons/io5";

const icons = {
  check: IoCheckmark,
  info: IoAlertCircleOutline,
  add: IoAdd,
  jump: IoArrowUndoOutline,
  delete: IoTrashOutline,
  copy: IoCopyOutline,
  edit: IoCreateOutline,
  close: IoCloseOutline,
  comment: IoChatboxOutline,
  github: IoLogoGithub,
  search: IoSearchOutline,
  enter: IoEnterOutline,
  actions: IoEllipsisVerticalSharp,
  refresh: IoRefresh,
  caretForward: IoCaretForward,
  caretDown: IoCaretDown,
  caretUp: IoCaretUp,
  chevronUp: IoChevronUp,
  chevronDown: IoChevronDown,
  folder: IoFolderSharp,
  file: IoDocumentOutline,
  menu: IoMenuOutline,
};

const styles = {
  container: css`
    cursor: pointer;
  `,
};

export type IconType = keyof typeof icons;

interface Props {
  name: keyof typeof icons;
  [key: string]: any;
}

export default function Icon({ name, ...rest }: Props) {
  const Component = icons[name];

  if (!Component) {
    return null;
  }
  return <Component className={styles.container} {...rest} />;
}
