export const clean = (url: string) => {
  let str = url.replace("//", "/");
  while (str.includes("//")) {
    str = str.replace("//", "/");
  }
  return str;
};

export const toQueryString = (params: Record<string, any>) => {
  return Object.keys(params)
    .map((key) => {
      const value = params[key];
      if (!value) {
        return "";
      }

      if (Array.isArray(value)) {
        return value.map((it: string) => `${key}[]=${it}`).join("&");
      }

      return `${key}=${params[key]}`;
    })
    .filter((it) => it)
    .join("&");
};
