import React, { createContext, useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { User } from "@services/api/models/user";
import { useQuery } from "@tanstack/react-query";
import { logout as logoutAsync } from "@services/api/auth";
import { fetchUser } from "@services/api/user";

export type AuthContextType = {
  fetched: boolean;
  context: {
    isPublic: boolean;
  };
  state: {
    user: User | undefined;
  };
  login: (user: User) => Promise<void>;
  logout: () => Promise<void>;
};

const AuthContext = createContext<AuthContextType | {}>({});

export const useAuth = () => {
  const auth = useContext(AuthContext) as AuthContextType;

  const { state, context, ...rest } = auth || {};
  const { user } = state || {};

  return {
    user,
    context,
    ...rest,
  };
};

export type Auth = ReturnType<typeof useAuth>

type Props = {
  isPublic: boolean;
  children: React.ReactNode;
};

export function Provider({ isPublic, children }: Props) {
  const [user, setUser] = useState<User | undefined>();

  const userQuery = useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      const res = await fetchUser();
      setUser(res.data);
      return res;
    },
    staleTime: 3 * 1000,
  });

  const login = async (user: User) => {
    setUser(user);
  };

  const logout = async () => {
    await logoutAsync();
    setUser(undefined);
    navigate("/auth/login");
  };

  useEffect(() => {
    if (userQuery.isFetched && !user && !isPublic) {
      navigate("/auth/login");
    }
  }, []);

  if (!user && !isPublic) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        fetched: userQuery.isFetched,
        context: { isPublic },
        state: { user },
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
