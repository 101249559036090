import ELDate from "@lib/date";

export const setupSetter =
  <K, V extends Base<K>>(factory: (v: K) => V) =>
  (target: V, key: keyof K, value: any) => {
    const cp = JSON.parse(JSON.stringify(target.raw));
    cp[key] = value;

    return factory(cp);
  };

export class Base<K> {
  private _raw: K;

  constructor(params: K) {
    this._raw = params;
  }

  get cloneRaw() {
    const raw = JSON.parse(JSON.stringify(this._raw));
    return new Base(raw);
  }

  get raw() {
    return this._raw;
  }
}

export type DateFields = {
  createdAt: ELDate;
  updatedAt: ELDate;
};

const defaultTimestampKeys = ["createdAt", "updatedAt"];

type Config = {
  timestampKeys?: string[];
};

export const handler = (config: Config) => ({
  get: (target: Base<any>, key: keyof Base<any>) => {
    const timestampKeys = config.timestampKeys || defaultTimestampKeys;
    if (timestampKeys.includes(key)) {
      if (!target.raw[key]) {
        return;
      }
      return new ELDate(target.raw[key] * 1000);
    }

    if (key in target) {
      return target[key];
    }

    return target.raw[key];
  },
  set: (_target: Base<any>, _key: keyof Base<any>, _value: any) => {
    throw new Error('Cannot set value directly, use "set" method instead');
  },
});
