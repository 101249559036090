import axios, { AxiosError } from "axios";
import { Deserializer } from "@lib/jsonapi";
import { serializeKeys, snakeCase } from "@lib/jsonapi/util";

const deserializer = new Deserializer();

const origin = process.env.GATSBY_API_ORIGIN || "http://localhost:8080";
const baseURL = `${origin}/api/v1`;

const isDevelopment = process.env.NODE_ENV !== "production";

export const instance = axios.create({
  baseURL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export type ReturnErrorHandler<T = any> = {
  e: AxiosError<T>;
  resolved: boolean;
}

type ErrorHandlerFunc<T = any> = (
  e: AxiosError<T>
) => Promise<ReturnErrorHandler<T>>;

const defaultErrorHandler = (e: any) => {
  throw e;
};

let errorHandler: ErrorHandlerFunc = defaultErrorHandler;
export const setErrorHandler = <T = any>(handler: ErrorHandlerFunc<T>) => {
  errorHandler = handler;
  return;
};

instance.interceptors.response.use(
  async function (response) {
    const raw = response.data;
    response.data = {
      __raw: raw,
      ...(deserializer.call(raw) || {}),
    };
    if (isDevelopment) {
      console.log("url", response.config.url, response.config.method);
      console.log("data", response.data.data);
      console.log("meta", response.data.meta);
      console.log("raw", response.data.__raw);
    }
    return response;
  },
  async function (error) {
    const res = await errorHandler(error);
    if (!res.resolved) {
      return Promise.reject(res.e);
    }
  },
);

instance.interceptors.request.use(
  function (config) {
    if (config.data) {
      const serialized = serializeKeys(snakeCase)(config.data);
      config.data = serialized;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);
