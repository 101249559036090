import ja from "./ja";
import en from "./en";

type Locale = "en" | "ja";

const locales: Record<Locale, any> = {
  ja,
  en,
};

type Dict = {
  value: string;
  t: <T>(key: keyof T) => Dict;
};

function t<T>(obj: T, key: keyof T): Dict {
  if (!obj[key]) {
    return t<{ [x: string]: keyof T }>({ [key]: key }, key as string);
  }

  const value = obj[key];

  return {
    value: value?.toString() || key.toString(),
    t: (kk) => {
      return t<typeof value>(value, kk as keyof typeof value);
    },
  };
}

export const translate = (locale: Locale) => {
  return t(locales, locale);
};
