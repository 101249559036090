import { navigate } from "gatsby";

export const navigateWith = (to: string, cb: () => void) => {
  navigate(to);

  let id: any;

  let count = 0;
  id = setInterval(() => {
    if (location.pathname === to || location.pathname === `${to}/`) {
      cb();
      clearInterval(id);
    }

    if (count >= 10) {
      clearInterval(id);
    }

    count++;
  }, 500);
};
