import { handler, setupSetter, Base, DateFields } from "@lib/models";
import AppDate from "@lib/date";
import {
  OauthGithubParams,
  OauthGithub,
  factory as githubFactory,
} from "./oauth/github";

interface Oauth<T = any> {
  github?: T;
}

type Consent = {
  id: string;
  userId: string;
  createdAt: number;
  updatedAt: number;
}

type Status = "active" | "wait_for_activate" | "deleted" | "banned";

export interface UserParams {
  id: string;
  kind: string;
  username: string;
  email: string;
  consent?: Consent;
  oauth: Oauth<OauthGithubParams>;
  status: Status;

  lastLoginAt: number;
  createdAt: number;
  updatedAt: number;
}

export class UserModel extends Base<UserParams> {
  oauth: Oauth<OauthGithub> = {};
  constructor(params: UserParams) {
    super(params);

    if (params.oauth.github) {
      this.oauth.github = githubFactory(params.oauth.github);
    }
  }

  get shouldConsent() {
    return !this.raw.consent;
  }

  get isWaitForActivate() {
    return this.raw.status === "wait_for_activate";
  }

  get avatarUrl() {
    if (!this.oauth.github) {
      return "";
    }
    return this.oauth.github.data.avatarUrl;
  }
}

export type User = UserModel &
  UserParams &
  DateFields & { commentedAt: AppDate };

const dateFields = ["lastLoginAt", "createdAt", "updatedAt"];

export const factory = (params: UserParams): User =>
  new Proxy<User>(
    new UserModel(params) as User,
    handler({ timestampKeys: dateFields }),
  );

export const set = setupSetter<UserParams, User>(factory);
