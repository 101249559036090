import { instance } from "./index";
import { factory } from "./models/user";

export const fetchUser = async () => {
  const _path = `/user`;
  const res = await instance.get(_path);
  res.data.data = factory(res.data.data);
  return res.data;
}

export const createRegistration = async () => {
  const _path = `/user/registrations`;
  const res = await instance.post(_path);
  return res.data;
}

export const activateUser = async ({ token }: { token: string }) => {
  const _path = `/user/registrations/${token}`;
  const res = await instance.patch(_path, { consent: true });
  return res.data;
}

